.FirstPage {
  display: flex;
  flex-direction: column;
  text-align: start;
  justify-content: center;
}

.button-container {
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 20px;
}