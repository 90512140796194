.LayoutArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  height: 100%;
}

.LayoutArea > .center {
  display: flex;
  
  height: 99%;
  width: 100%;
  box-sizing: border-box;
}



.LayoutArea > .center > .content {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  width: 100%;
text-align: end ; 
  /* height: 86%; */
  
  margin: 50px;
}



.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}