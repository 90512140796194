

.lastPage h2, .lastPage button {
    margin-bottom: 15px; /* Adjust as needed for spacing */
    text-align: center;
  }
  
  .lastPage button {
    display: block; /* Ensures the button starts on a new line */
    /* Additional styling for the button can be added here */
    margin: 0 auto;
  }