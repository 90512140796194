body, html, #root {
  margin: 0;
  padding: 0;
  /* direction: rtl; */
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
  width: 100%;
  background-color: rgb(13 36 56);
  color: rgb(243 244 246);
  display: flex;
  box-sizing: border-box;
  font-size: large;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  padding: 10px;
  margin: 20px;
  background-color: rgb(59 130 246);
  border-radius: 9999px;
  width: 200px;
  color: rgb(255, 255, 255);
  font-size: large;
}
button.active {
  background-color: rgb(16, 49, 106);
}

button:hover {
  cursor: pointer;
}





button:disabled {
  background-color: rgb(114, 114, 114);
  cursor: not-allowed;
}