.SecondPage {
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
  }
  
  .SecondPage > .button-container {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 20px;
    
  }

  .SecondPage > img {
    width: 300px;
  }

  .SecondPage h2, p{
font-size: 24px;
font-weight: 400;
  }