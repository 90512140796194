.plan-card {
    padding: 0;
    margin: 0;
    color: black;
}

.plan-card > .cardHeader > h2 {
    padding: 10px;
    margin: 0;
    text-align: center;
    color: white;
    height: 30%;
}

.plan-card > .cardSecondHeader {
    height: 25px;
    display: flex;
    width: 100%;
   
    
}
.plan-card > .cardSecondHeader > .when {
height: 100%;
width: 75%;
text-align: center;
}
.plan-card > .cardSecondHeader > .howMany {
height: 100%;
width: 25%;
text-align: center;
}


.plan-card > .cardBottom {
    width: 100%;
    height: 72%;
    display: flex;

   
}

.cardBottomRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 92%;
}

.cardBottomLeft {
    width: 75%;
    height: 92%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

    table, th, td {
      background-color: #2deb65;
   
      text-align: center;
      font-size: 26px;
    }
    
    table{
    border-collapse: collapse;
    }
    
    
    th{
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 3px solid black;
        border-right: 3px solid black;
    }
    
    td{
        padding-top: 10px;
        padding-bottom: 10px;
        border:1px solid black;
        border-right: 3px solid black;
        width: 230px;
    }