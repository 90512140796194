.RandomNumbersComponent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.couple {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

 
    gap: 50px;
}
.column1 {
    display: flex;
    flex-direction: column ;
    box-sizing: border-box;
    gap: 20px;

    align-items: flex-end;
    /* background-color: rgb(59 130 246); */
    
}
.column2 {
    display: flex;
    flex-direction: column ;
    gap: 20px;

    align-items: flex-end;
    /* background-color: rgb(59 130 246); */
}

.hebrewText {
    text-align: left;
  }

  .planSelectionButtons {
    display: flex;

    
    
    width: 100%;
    justify-content: space-around;
  }

  .planSelectionButtons > button {
    padding: 10px;
    margin: 20px;
    background-color: rgb(59 130 246);
    border-radius: 9999px;
    width: 150px;
    color: rgb(255, 255, 255);
  }
  .planButton.active {
    background-color: rgb(16, 49, 106);
  }
  
  .messageDiv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(13 36 56); /* Add an opaque background color */
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-size: xx-large;
    z-index: 999; /* Ensure the message div is on top of other elements */
  }
  
  .messageContent {
    text-align: center; /* Center the text inside the message div */
    /* Add any additional styles for the message content */
    
  }
  
  .messageDiv button {
    margin-top: 20px;
    width: 50px;

  }

  .blackScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: black; */
    background-color: rgb(13 36 56); 
    z-index: 1000; /* Ensure it's above other elements */
}

